import Swiper from 'swiper';

class HeadlineTextSlider {
    constructor(selector = '.js-headline-text-slider')
    {
        /*
        super(selector, {
            ...settings,
            navigation: {
                nextEl: '.js-headline-text-slider-navigation--next',
                prevEl: '.js-headline-text-slider-navigation--prev'
            },
            slidesPerView: 1,
			loop: true,
			spaceBetween: 0,
            breakpoints: {
                768: {
                  slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        });
        */

        new Swiper(selector, {
            navigation: {
                nextEl: '.js-headline-text-slider-navigation--next',
                prevEl: '.js-headline-text-slider-navigation--prev'
            },
            slidesPerView: 1,
            loop: true,
			spaceBetween: 0,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                  },
                  1024: {
                      slidesPerView: 2,
                  },
                  1200: {
                      slidesPerView: 3,
                  }
            }
        });

    }
}

export default HeadlineTextSlider;
