class ImageTitleTextLabels {
    constructor(selector = '.js-o-image-title-text-labels')
    {
        this.reveal = this.reveal.bind(this);
        this.revealOnIntersect = this.revealOnIntersect.bind(this);

        this.elements = document.querySelectorAll(selector);
        this.observer = 'IntersectionObserver' in window ? new IntersectionObserver(this.revealOnIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: 1.0
        }) : null;
    }

    reveal(element) {
        element.classList.add('is-reveal')
    }

    revealOnIntersect(elements) {
        elements.forEach((element) => {
            if (element.isIntersecting === true) {
                this.reveal(element.target);
            }
        });
    }

    init() {
        window.addEventListener('load', () => {
            const { elements, observer } = this;

            if (elements.length === 0) {
                return;
            }

            elements.forEach((element) => {
                if (observer) {
                    observer.observe(element);
                } else {
                    this.reveal(element);
                }
            });
        });
    }
}

export default ImageTitleTextLabels;
