class BlogPostList {
  constructor() {
    this.form = document.querySelector('.js-blog-post-filter');
    this.state = {};
  }

  init() {
    if (this.form) {
      const filters = this.form.querySelectorAll('.js-blog-post-filter-item');

      filters.forEach((filter) => {
        const globalCheckbox = filter.querySelector('.js-blog-post-filter-global');

        if (globalCheckbox && globalCheckbox.dataset.value) {
          const allCheckbox = filter.querySelector('.js-blog-post-filter-all');
          const childrenCheckboxes = filter.querySelectorAll('.js-blog-post-filter-child');

          const parentCategoryName = globalCheckbox.dataset.value;

          this.state[parentCategoryName] = [];

          // init state for parent category
          if (allCheckbox.checked) {
            this.state[parentCategoryName].push('all');
          }

          globalCheckbox.addEventListener('click', (e) => this.handleAllCheckboxClick(e, allCheckbox, childrenCheckboxes));
          allCheckbox.addEventListener('click', (e) => this.handleAllCheckboxClick(e, globalCheckbox, childrenCheckboxes));

          filter.addEventListener('click', () => {
            console.log(filter);
            this.handleMobileToggle(filter);
          });

          const preCheckedItems = [];

          childrenCheckboxes.forEach((childCheckbox) => {
            if (childCheckbox.checked) {
              preCheckedItems.push(childCheckbox.dataset.value);
            }

            childCheckbox.addEventListener('click', (e) => this.handleChildCheckboxClick(e, parentCategoryName, allCheckbox, globalCheckbox));
          });

          if (preCheckedItems.length > 0) {
            this.state[parentCategoryName] = preCheckedItems;
          }
        }
      });

      this.form.addEventListener('submit', (e) => this.handleFormSubmit(e));
    }
  }

  changeChildrenStatus(childrenCheckboxes, status) {
    childrenCheckboxes.forEach((childCheckbox) => {
      childCheckbox.checked = status;
    })
  }

  handleAllCheckboxClick(e, connectedField, childrenCheckboxes) {
    const isChecked = e.target.checked;
    const parentCategoryName = e.target.dataset.value;

    this.changeChildrenStatus(childrenCheckboxes, false);

    // if unchecked, clear all item, otherwise clear all items and push 'all'
    this.state[parentCategoryName] = [];

    if (isChecked) {
      this.state[parentCategoryName].push('all');
    }

    if (connectedField) {
      connectedField.checked = isChecked;
    }
  }

  handleChildCheckboxClick(e, parentCategoryName, allCheckbox, globalCheckbox) {
    const isChecked = e.target.checked;
    const categoryName = e.target.dataset.value;

    if (isChecked) {
      this.state[parentCategoryName].push(categoryName);
      this.state[parentCategoryName] = this.state[parentCategoryName].filter((item) => item !== 'all');

      globalCheckbox.checked = true;
      allCheckbox.checked = false;
    } else {
      if (this.state[parentCategoryName].includes(categoryName)) {
        this.state[parentCategoryName] = this.state[parentCategoryName].filter((item) => item !== categoryName);

        if (this.state[parentCategoryName].length === 0) {
          allCheckbox.checked = false;
          globalCheckbox.checked = false;
        }
      }
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();

    let queryParams = '';

    Object.keys(this.state).forEach((key) => {
      this.state[key].forEach((value) => {
        queryParams += `&${key}[]=${value}`;
      });
    });

    window.location.href = `${window.location.origin}${window.location.pathname}?${queryParams}`;
  }

  isTouchDevice() {
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0);
  }

  handleMobileToggle(filter) {
    if (this.isTouchDevice()) {
      console.log(filter.classList);
      if (filter.classList.contains('is-active')) {
        filter.classList.remove('is-active');
      } else {
        filter.classList.add('is-active');
      }
    }
  }
}




export default BlogPostList;
