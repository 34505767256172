import Carousel from './carousel';

class Slider extends Carousel {
    constructor(selector = '.js-slider', settings = {})
    {
        super(selector, {
            ...settings,
            loop: true,
            pagination: {
                el: '.js-slider-pagination',
                type: 'custom',
                renderCustom: function (swiper, current, total) {
                    return '<div>' + current + ' &bull; ' + total + '</div>';
                }
            },
            navigation: {
                nextEl: '.js-slider-navigation--next',
                prevEl: '.js-slider-navigation--prev'
            },
            on: {
                slideChange: function () {
                    const slideCaption = document.querySelector('.o-slider__caption-' + this.realIndex);
                    const currentActiveSlide = document.querySelector('.active-index');

                    if (currentActiveSlide) {
                        currentActiveSlide.classList.remove('active-index');
                    }

                    slideCaption.classList.add('active-index');
                }
            },
            slidesPerView: 1.1,
            spaceBetween: 25,
            breakpoints: {
                768: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: false
                }
            }
        });

      // swiper.init();
      // swiper.snapGrid[swiper.snapGrid.length - 1] = swiper.slidesGrid[swiper.slidesGrid.length - 1];
    }
}

export default Slider;
