import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);
class Carousel {
  constructor(scope, options = {}) {
    this.scope = scope;
    this.swiper = null;

    const defaultOptions = {
      loop: true,
      speed: 800,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    this.options = { ...defaultOptions, ...options };
  }



  init() {
    const { scope, options } = this;

    this.swiper = new Swiper(scope, options);
  }
}

export default Carousel;
