import 'unfetch/polyfill';
import Header from './components/header';
import SubscribeForm from './components/subscribe-form';
import Button from './components/button';
import Accordion from './components/accordion';
import Slider from './components/slider';
import SliderText from './components/slider-text';
import VideoModal from './components/video-modal';
import BlogPostList from './components/blog-post-list';
import StudentsGrid from './components/students-grid';

class App {
  constructor() {
    this.header = new Header();
    this.subscribeForm = new SubscribeForm();
    this.button = new Button();
    this.accordion = new Accordion();
    this.slider = new Slider();
    this.SliderText = new SliderText();
    this.videoModal = new VideoModal();
    this.blogPostList = new BlogPostList();
    this.studentsGrid = new StudentsGrid();
  }

  init() {
    this.header.init();
    this.subscribeForm.init();
    this.button.init();
    this.accordion.init();
    this.slider.init();
    this.SliderText.init();
    this.videoModal.init();
    this.blogPostList.init();
    this.studentsGrid.init();
  }
}

const app = new App();
app.init();
