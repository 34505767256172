class VideoModal {
  playVideo(video) {
    video.classList.add('is-open');
    document.getElementsByClassName('js-modal-backdrop')[0].classList.add('is-visible');
    video.getElementsByTagName('video')[0].play();
  }

  closeVideo(video) {
    video.classList.remove('is-open');
    document.getElementsByClassName('js-modal-backdrop')[0].classList.remove('is-visible');
    video.getElementsByTagName('video')[0].pause();
  }

  handleClose(e, video) {
    if (e.key === 'Escape') {
      this.closeVideo(video);
    }
  }

  init() {
    const playButtons = document.querySelectorAll('.js-video-play');

    playButtons.forEach((play) => {
      play.addEventListener('click', (e) => {
        e.preventDefault();

        const videoId = play.dataset.videoId;

        if (videoId) {
          const video = document.querySelector(`.js-video-${play.dataset.videoId}`);

          this.playVideo(video);

          document.addEventListener('keyup', (e) => this.handleClose(e, video));
        } else {
          console.error('Vimeo video id is missing!');
        }
      });
    });

    const closeButtons = document.querySelectorAll('.js-video-close');

    closeButtons.forEach((close) => {
      close.addEventListener('click', (e) => {
        e.preventDefault();

        const videoId = close.dataset.videoId;

        if (videoId) {
          const video = document.querySelector(`.js-video-${videoId}`);

          this.closeVideo(video);

          document.removeEventListener('keyup', (e) => this.handleClose(e, video));
        }
      });
    });
  }
}

export default VideoModal;
