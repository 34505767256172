class Button {
  constructor() {
    this.buttons = document.querySelectorAll('.a-button');
  }

  bindings() {
    if (!this.buttons) {
      return;
    }

    this.buttons.forEach(button => {
      button.addEventListener('click', (e) => {
        const navigateTo = button.getAttribute('data-navigate');
        const scrollTo = document.querySelector(`#${navigateTo}`);
        if (!navigateTo || !scrollTo) {
          return;
        }
        e.preventDefault();
        window.scroll({
          top: scrollTo.getBoundingClientRect().top,
          behavior: 'smooth'
        });
      });
    });
  }

  init() {
    this.bindings();
  }
}
export default Button;
