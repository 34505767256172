//import Carousel from './carousel';

import Swiper from 'swiper';

class SliderCaption  {
    constructor(selector = '.js-slider-caption')
    {
        this.buttons = document.querySelectorAll('.js-slider-caption-button-player');

        new Swiper(selector, {
            loop: true,
            pagination: {
                el: '.js-slider-caption-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.js-slider-caption-navigation--prev',
                prevEl: '.js-slider-caption-navigation--next'
            },
            slidesPerView: 1,
            spaceBetween: 20,
            autoHeight: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        });

        /*
        super(selector, {
            ...settings,
            loop: true,
            pagination: {
                el: '.js-slider-caption-pagination',
                clickable: true
            },
            slidesPerView: 1,
            spaceBetween: 20,
            autoHeight: true,
            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                }
            }
        });
        */

      // swiper.init();
      // swiper.snapGrid[swiper.snapGrid.length - 1] = swiper.slidesGrid[swiper.slidesGrid.length - 1];
    }

    bindings()
    {
        if (!this.buttons) {
            return;
        }

        this.buttons.forEach((button) => {
            button.addEventListener('click', function () {
                button.closest('.js-slider-caption-item').classList.add('video_active');

                const video_container = button.closest('.js-slider-caption-item').querySelector('.js-video-container');
                const iframe = video_container.querySelector('iframe');

                if (iframe) {
                    iframe.src += '&autoplay=1';
                }
            });
        });
    }

    init()
    {
        this.bindings();
    }
}

export default SliderCaption;
