//import Carousel from './carousel';

import Swiper from 'swiper';

class LogoSoup {
    constructor(selector = '.js-logo-soup')
    {
        new Swiper(selector, {
            pagination: {
                el: '.js-logo-soup-pagination',
				clickable: true
            },
            slidesPerView: 2,
			loop: true,
			spaceBetween: 0,
            breakpoints: {
                768: {
                  slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5,
                },
                1440: {
                    slidesPerView: 6,
                }
            }
        });


        /*
        super(selector, {
            ...settings,
            pagination: {
                el: '.js-logo-soup-pagination',
				clickable: true
            },
            slidesPerView: 2,
			loop: true,
			spaceBetween: 0,
            breakpoints: {
                768: {
                  slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5,
                },
                1440: {
                    slidesPerView: 6,
                }
            }
        });
        */
    }

    init() {}
}

export default LogoSoup;
