class Header {
  constructor() {
    this.body = document.querySelector('body');
    this.nav = document.querySelector('.js-nav');
    this.navOpen = document.querySelector('.js-nav-open');
    this.navClose = document.querySelector('.js-nav-close');
    this.logo = document.querySelector('.l-header__logo a img');
    this.has_dropdown = document.querySelectorAll('.has_dropdown');
  }

  bindings() {
    if (!this.nav || !this.navClose || !this.navOpen) {
      return;
    }

    this.navOpen.addEventListener('click', () => {
      this.nav.classList.add('is-open');
      this.logo.classList.add('invert');
      this.body.classList.add('is-frozen');

      setTimeout(() => {
        this.navClose.classList.add('is-visible');
      }, 300);
    });

    this.navClose.addEventListener('click', () => {
      this.nav.classList.remove('is-open');
      this.navClose.classList.remove('is-visible');
      this.logo.classList.remove('invert');
      this.body.classList.remove('is-frozen');
    });

    this.has_dropdown.forEach(function (element) {
      element.addEventListener('click', function () {
        element.closest('.dropdown').classList.toggle('open');
      });
    });
  }

  init() {
    this.bindings();
  }
}
export default Header;
