class StudentsGrid {
  constructor() {
    this.buttons = document.querySelectorAll('.js-students-grid-button');
  }

  init() {
    if (!this.buttons) return;

    const that = this;

    this.buttons.forEach(button => {
      if (!button)  return;

      button.addEventListener('click', () => {
        that.buttons.forEach(b => {b.classList.remove('active');});

        button.classList.add('active');
      });
    });
  }
}

export default StudentsGrid;
