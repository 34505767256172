import serialize from 'form-serialize';

class SubscribeForm {
  constructor() {
    this.forms = document.querySelectorAll('.js-subscribe-form');
  }

  init() {
    if (!this.forms) {
      return;
    }

    this.forms.forEach(form => {
      if (!form) {
        return;
      }

      const button = form.querySelector('button');

      const newsletterRedirect = form.querySelector('#js_newsletter_redirect');
      const redirectUrl = newsletterRedirect ? newsletterRedirect.value : false;

      form.addEventListener('submit', (e) => {
        e.preventDefault();

        if (button) {
          button.disabled = true;
        }

        form.classList.remove('is-success', 'is-error');

        const url = window.gzData ? window.gzData.ajaxUrl : null;
        const data = serialize(form, { hash: true });

        if (!url) {
          form.classList.add('is-error');
          return;
        }

        let body = `action=gz_form_subscribe&email=${data.email}`;
        if (Object.prototype.hasOwnProperty.call(data, 'tag_type')) {
          body = body + `&tag_type=${data.tag_type}`;
        }

        fetch(window.gzData.ajaxUrl, {
          method: 'post',
          body: body,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
          }
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error();
          }

          if (redirectUrl) window.location.href = redirectUrl;

          return response.json();
        })
        .then(() => {
          form.classList.add('is-success');
          form.classList.remove('is-error');
        })
        .catch(() => {
          if (button) {
            button.disabled = false;
          }

          form.classList.add('is-error');
          form.classList.remove('is-success');
        });
      });
    });
  }
}

export default SubscribeForm;
