import Carousel from './carousel';

class SliderText extends Carousel {
    constructor(selector = '.js-slider-text', settings = {})
    {
        super(selector, {
            ...settings,
            pagination: {
                el: '.js-slider-text-pagination',
				clickable: true
            },
            navigation: {
                nextEl: '.js-slider-text-navigation--next',
                prevEl: '.js-slider-text-navigation--prev'
            },
            slidesPerView: 1,
			loop: false,
			spaceBetween: 0
        });
    }
}

export default SliderText;
