class Accordion {
    constructor()
    {
        this.accordions = document.getElementsByClassName('js-accordion');
    }

    bindings()
    {
        if (!this.accordions) {
            return;
        }

        for (let i = 0; i < this.accordions.length; i++) {
            this.accordions[i].addEventListener('click', function () {
                this.classList.toggle('active');

                const panel = this.nextElementSibling;

                if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                } else {
                    panel.style.maxHeight = panel.scrollHeight + 'px';
                }
            });
        }

    }

    init()
    {
        this.bindings();
    }
}

export default Accordion;
